var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "path-auth" }, [
    _c("div", { staticClass: "form-card" }, [
      _vm._m(0),
      _vm._m(1),
      _c(
        "form",
        {
          staticClass: "form-login",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit()
            },
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "input-login",
            attrs: {
              type: "email",
              placeholder: "Email",
              name: "email",
              required: "",
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "input-login",
            attrs: {
              type: "password",
              placeholder: "Senha",
              name: "password",
              required: "",
            },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.password = $event.target.value
              },
            },
          }),
          _c(
            "button",
            {
              staticClass: "button-login",
              attrs: {
                type: "submit",
                color: "primary",
                depressed: "",
                loading: _vm.loading,
              },
            },
            [_vm._v(" Login ")]
          ),
        ]
      ),
    ]),
    _vm.snackbar
      ? _c("div", { staticClass: "snackbar", attrs: { timeout: 5000 } }, [
          _vm._v(" " + _vm._s(_vm.error) + " "),
          _c(
            "button",
            _vm._b(
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    _vm.snackbar = false
                  },
                },
              },
              "button",
              _vm.attrs,
              false
            ),
            [_vm._v("Close")]
          ),
        ])
      : _vm._e(),
    _c("img", {
      staticClass: "gc-logo",
      attrs: {
        src: "https://assets.gamersclub.com.br/header/img/gamers_club_logo.4e618b33.svg",
        alt: "logogc",
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-title" }, [
      _c("h1", { staticClass: "title-login" }, [_vm._v("Console")]),
      _c("h1", { staticClass: "title-login-dot" }, [_vm._v(".")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-subtitle" }, [
      _c("span", [_vm._v("um novo backoffice")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }