<template>
  <div class="path-auth">
    <div class="form-card">
      <div class="card-title">
        <h1 class="title-login">Console</h1>
        <h1 class="title-login-dot">.</h1>
      </div>
      <div class="card-subtitle">
        <span>um novo backoffice</span>
      </div>
      <form @submit.prevent="submit()" class="form-login">
        <input
          type="email"
          v-model="email"
          placeholder="Email"
          name="email"
          required
          class="input-login"
        />

        <input
          type="password"
          v-model="password"
          placeholder="Senha"
          name="password"
          required
          class="input-login"
        />

        <button
          type="submit"
          color="primary"
          depressed
          class="button-login"
          :loading="loading"
        >
          Login
        </button>
      </form>
    </div>

    <div v-if="snackbar" :timeout="5000" class="snackbar">
      {{ error }}
      <button text v-bind="attrs" @click="snackbar = false">Close</button>
    </div>
    <img
      src="https://assets.gamersclub.com.br/header/img/gamers_club_logo.4e618b33.svg"
      alt="logogc"
      class="gc-logo"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { load } from 'recaptcha-v3'
import { _shared } from './shared/shared'
const $auth = _shared.$auth

export default {
  name: 'App',
  components: {},
  data() {
    return {
      email: null,
      password: null,
      loading: false,
      snackbar: false,
      error: null,
    }
  },
  methods: {
    async submit() {
      this.loading = true

      let recaptchaKey = '6Lc9btAZAAAAAPUG5YAskEjxJWdEBz3yS5jc7cac'

      try {
        let url =
          process.env.MULTIGAMING_API_URL + '/backoffice/accounts/signin'
        if (process.env.APP_ENV === 'production') {
          recaptchaKey = '6LfCZtAZAAAAAAYZwfvOKPuFCkwewAWCNgjYNUcL'
          url = process.env.MULTIGAMING_API_URL + '/backoffice/accounts/signin'
        }

        const recaptcha = await load(recaptchaKey)
        const recaptchaToken = await recaptcha.execute('')

        const response = await axios.post(url, {
          email: this.email,
          password: this.password,
          recaptchaToken,
        })

        const authToken = response.headers.authorization
        const refreshToken = response.headers['x-refresh-token']
        const user = response.data.data

        $auth.login({
          authToken,
          refreshToken,
          user,
          callback() {
            window.location.pathname = '/apps/marketplace'
          },
        })
      } catch (e) {
        this.snackbar = true
        this.error = e?.response?.data?.errors?.[0] ?? e?.message
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
  cursor: pointer;
}
.path-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  margin-top: -4rem;
}

.form-card {
  margin: 0 1rem;
  min-width: 30rem;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 0.5rem;
  min-height: 30vh;
  padding: 2rem;
}
.card-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.input-login {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  min-height: 3rem;
  margin: 1rem;
  width: 70%;
}
.button-login {
  background-color: #1976d2;
  color: #fff;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  min-height: 3rem;
  margin: 1rem;
  width: 70%;
}
.title-login {
  color: #fff;
  margin-left: 0;
}
.title-login-dot {
  color: #1976d2;
}
.card-subtitle span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1976d2;
}
.gc-logo {
  position: absolute;
  bottom: 20px;
}
.snackbar {
  position: absolute;
  bottom: 10rem;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.25rem;
  button {
    color: red;
  }
}
</style>
